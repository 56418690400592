<template>
  <div class="relative">
    <img
      src="@/Core/assets/images/home/ecobanner.png"
      alt="image"
      class="w-full banner-h500px sm-banner350px object-cover"
    />

    <div
      class="absolute h-full items-end lg:top-12 top-2 top-0 left-0 lg:py-16 md:py-10 py-5 left-0 lg:px-24 md:px-24 px-5 w-full lg:flex md:flex block"
    >
      <div class="w-full pb-4">
        <span class="cursor-pointer font-bold text-primary cursor-pointer sm-fsize12px"
          >Economy
        </span>

        <h2
          class="font-bold min-fsize40 text-white py-4 lg:w-3/5 md:w-8/12 w-full sm-fsize22px"
        >
          {{ postDetail.imageText }},
          <br />
          recession ‘very unlikely’
        </h2>

        <div class="flex justify-between items-center">
          <p class="text-white font-semibold">
            {{
              new Date(postDetail.post_created_datetimestamp).toLocaleString()
            }}
          </p>

          <p class="text-white font-semibold">
            Published by {{ postDetail.post_Created_by }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      centerDialogVisible55: false,
    };
  },
  computed: {
    ...mapState("post", ["postDetail"]),
  },
  // mounted() {
  //   console.log(this.postDetail);
  // },
};
</script>
