<template>
  <div>
    <Banner />

    <div class="lg:py-16 md:py-10 py-8 lg:px-24 md:px-24 px-5">
      <div class="lg:flex md:flex">
        <div class="lg:w-2/3 md:w-2/3 lg:pr-6 md:pr-6 pr-0">
          <EcoLeft />
        </div>
        <div class="lg:w-1/3 md:w-1/3 lg:pl-6 md:pl-6 pl-0 lg:mt-0 md:mt-0 mt-4 lg:mb-0 md:mb-0 mb-4">
          <EcoRight />
        </div>
      </div>
    </div>

    <div class="">
      <SubscribeBanner />
    </div>

    <div class="lg:py-16 md:py-10 py-8 lg:px-24 md:px-24 px-5">
      <IntrestingRead />
    </div>
  </div>
</template>
<script>
import IntrestingRead from "./components/IntrestingRead";
import SubscribeBanner from "../homepage/components/SubscribeBanner";
import EcoLeft from "./components/EcoLeft.vue";
import EcoRight from "./components/EcoRight.vue";
import Banner from "./components/Banner";

export default {
  data() {},
  components: {
    IntrestingRead,
    Banner,
    SubscribeBanner,
    EcoLeft,
    EcoRight,
  },
};
</script>
