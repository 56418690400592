<template>
  <div class="relative">
    <img
      src="@/Core/assets/images/home/subsbanner.png"
      alt="image"
      class="w-full bannerdeal-height sm-bannerdeal-height object-cover"
    />

    <div
      class="absolute h-full items-center lg:top-12 top-2 top-0 left-0 lg:py-16 md:py-10 py-10 left-0 lg:px-24 md:px-24 px-5 w-full lg:flex md:flex block"
    >
      <div class="lg:w-3/5 md:w-8/12 w-full">
        <span class="font-bold text-primary cursor-pointer"
          >Kotak Whatsapp Insights
        </span>

        <h2 class="font-bold min-fsize40 text-white lg:py-4 py-1 sm-fsize22px">
          Subscribe for our latest <br />
          updates on WhatsApp
        </h2>

        <div class="mt-4">
          <vs-button color="success">
            <div class="flex lg:gap-3 gap-2 lg:py-2 py-1 items-center">
              <img
                src="@/Core/assets/images/home/whatsappicon.png"
                alt="whatsapp-icon"
                class="w-5 h-5"
              />
              <p class="uppercase font-bold" @click="openWhatsApp">
                SUBSCRIBE NOW
              </p>
            </div>
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      centerDialogVisible55: false,
    };
  },
  methods: {
    openWhatsApp() {
      let singlePostLink = `https://api.whatsapp.com/send/?phone=2266006022&text=Subscribe&type=phone_number&app_absent=0`;
      window.open(singlePostLink, "_blank");
    },
  },
};
</script>
<style scoped>
@media (max-width: 640px) {
  .sm-btn-size {
    font-size: 12px;
  }
}
</style>
