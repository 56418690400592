<template>
  <div>
    <h2 class="text-dark font-bold min-fsize40 sm-fsize22px">Interesting Reads</h2>
    <swiper
      class="lg:mt-10 md:mt-10 mt-2 lg:mb-0 md:mb-0 mb-20 py-4"
      :options="swiperOption"
      ref="mySwiper"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      :key="$vs.rtl"
    >
      <swiper-slide
        v-for="(item, index) in policyCards"
        :key="'item-' + index"
        class="cursor-pointer shadow-lg"
      >
        <img
          :src="item.img"
          alt="img"
          class="w-full latest-img-h object-cover"
        />

        <div class="p-4 bg-f2f2f2">
          <h4 class="text-3d3d3d font-semibold min-fsize22 sm-fsize18px">
            {{ item.title }}
          </h4>

          <div class="flex justify-between items-center mt-4">
            <p class="text-9e9e9e font-semibold">{{ item.name }}</p>
            <p class="text-9e9e9e font-semibold">{{ item.date }}</p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  data() {
    return {
      swiperOption: {
        slidesPerView: 3.3,
        centeredSlides: false,
        spaceBetween: 25,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        breakpoints: {
          1024: {
            slidesPerView: 3.3,
            spaceBetween: 25,
          },
          1240: {
            slidesPerView: 3.3,
            spaceBetween: 25,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          375: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
        },
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
      },
      policyCards: [
        {
          id: "01",
          img: require("@/Core/assets/images/home/latest/b1.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "02",
          img: require("@/Core/assets/images/home/latest/b2.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "03",
          img: require("@/Core/assets/images/home/latest/b4.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },

        {
          id: "04",
          img: require("@/Core/assets/images/home/latest/b5.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "05",
          img: require("@/Core/assets/images/home/latest/b6.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "06",
          img: require("@/Core/assets/images/home/latest/b7.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
      ],
    };
  },
  components: {
    swiper,
    swiperSlide,
  },
};
</script>
